/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import React, { useEffect, useState } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const ellipsis = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};


const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length
    ? `${"0".repeat(length - number.length)}${number}`
    : number;
};


// Get User Data From Local Storage
const userData = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  return userData;
};

const ImageUploader = ({ imageUrl, handleChange, form, page }) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const multiImage = page == "addPainting" ? true : false;

  // Image Uploader
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result, img));
    reader.readAsDataURL(img);
  };

  // Before Image Upload
  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    }
  };
  // Handle Image Change
  const handleImageChange = (info) => {
    if (info.file && info.file.originFileObj instanceof Blob) {
      getBase64(info.file.originFileObj, (base64) => {
        setLoadingImg(false);
        handleChange(info.file.originFileObj, base64);
      });
    } else {
      setLoadingImg(false);
      handleChange(null, null);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  // console.log(imageUrl)
  return (
    <>
      <Upload
        name="image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={multiImage}
        multiple={multiImage}
        maxCount={multiImage ? 10 : 1}
        accept=".jpg, .jpeg, .png"
        beforeUpload={beforeUpload}
        onChange={handleImageChange}
        customRequest={() => {}}
      >
        {!multiImage && imageUrl ? (
          <img src={imageUrl} alt="image" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};

// Image Upload and Remove - Based on (In-Page-Api)
export const ImageUpload = ({ form, editable, imageUrl, handleChange, multiImage, disabled, deletImg, baseUrl } , ...props ) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if(editable){
      if (imageUrl && imageUrl?.length > 0) {
        const checkArrary = Array.isArray(imageUrl) ? imageUrl : [imageUrl];
        const fileListData = checkArrary?.map((url, index) => ({
          uid: index,
          name: `image-${index + 1}`,
          status: "done",
          url: baseUrl + url,
        }));
        setFileList(fileListData);
        validateImages(fileListData);
      } else {
        setFileList([])
      }
    }
  }, [imageUrl]);

  const onChange = ({ fileList }) => {
    // Check if the file size is less than 5 MB (5 MB)
    const list = fileList?.filter((data) => {
      const isLt5M = data.size / 1024 / 1024 < 5;
      if (!isLt5M && data.size) {
        message.error("Image must be smaller than 5MB!");
        return
      }
      return !data?.size || data?.size / 1024 / 1024 < 5;
    });
    setFileList(list);
    validateImages(list);
  };

  const onRemove = (file) => {
    const imgName = file?.url?.replace(baseUrl, ""); // Get the image name from the URL and pass it to the deletImg function
    if (imgName && deletImg) {
      const type = multiImage ? "product" : "cover" ;
      deletImg(type, imgName);
    }
    const updatedFileList = fileList?.filter((item) => item?.uid !== file?.uid);    // Filter out the removed file from the fileList state
    setFileList(updatedFileList);
  };

  const validateImages = (fileObj)=>{ //Validate Image validation
    const originFileObjs =  fileObj
    ?.filter((data) => data?.originFileObj) 
    ?.map((data) => data?.originFileObj)

    if (handleChange) {
      handleChange(originFileObjs, fileObj);
    }
  }

  const uploadButton = (
    <div>
       <PlusOutlined />
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <Upload
      name="image"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={true}
      multiple={multiImage}
      maxCount={multiImage ? 100 : 1}
      accept=".jpg, .jpeg, .png"
      onChange={onChange}
      fileList={fileList}
      onRemove={onRemove}
      beforeUpload={() => false} // Disable automatic upload  || disiseble action api hit
      disabled={disabled}
      {...props}
    >
      { multiImage ?
        fileList?.length < 10 && (uploadButton)
        :
        fileList?.length < 1 && (uploadButton)
      }
    </Upload>
  );
};

// CSV File Upload
export const CSVUpload = ({setFile, children}) => {
  const props = {
    name: 'file',
    accept: '.csv',
    showUploadList: false,
    beforeUpload: () => false,
    onChange(info) {
      
      const isCSV = info.file.type === 'text/csv';
      if (!isCSV) {
        message.error(`${info.file.name} is not a csv file`);
      } else {
        setFile(info.file);
      }
    },
  };

  return (
    <Upload {...props}>
      {children}
    </Upload>
  )
}

// Xlsx File Upload
export const XlsxUpload = ({setFile, children}) => {
  const props = {
    name: 'file',
    accept: '.xlsx',
    showUploadList: false,
    beforeUpload: () => false,
    onChange(info) {
      const isCSV = info.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isCSV) {
        message.error(`${info.file.name} is not a xlsx file`);
      } else {
        setFile(info.file);
      }
    },
  };

  return (
    <Upload {...props}>
      {children}
    </Upload>
  )
}


// Banner Image Upload - Based on (In-Page-Api)
const BannerImageUpload = ({ imageUrl, handleChange, form, page, disabled }, ...props ) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const multiImage = page == "addPainting" ? true : false;
  
  // Image Uploader
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result, img));
    reader.readAsDataURL(img);
  };

  // Before Image Upload
  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    }
  };
  // Handle Image Change
  const handleImageChange = (info) => {
    if (info.file && info.file.originFileObj instanceof Blob) {
      getBase64(info.file.originFileObj, (base64) => {
        setLoadingImg(false);
        handleChange(info.file.originFileObj, base64);
      });
    } else {
      setLoadingImg(false);
      handleChange(null, null);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <>
      <Upload
        name="image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={multiImage}
        multiple={multiImage ? true : false}
        maxCount={multiImage ? 10 : 1}
        accept=".jpg, .jpeg, .png"
        beforeUpload={beforeUpload}
        onChange={handleImageChange}
        customRequest={() => {}}
        disabled={disabled}
        props
      >
        {!multiImage && imageUrl ? (
          <img src={imageUrl} alt="image" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};


// Single Image Uploader - Based on (Out-Page-Api)
const SingleImageUploader = ({ imageUrl, handleChange, form, page, editable, setImg, }) => {
  const data = localStorage.getItem("token") || "";
  const multiImage = page == "addPainting" ? true : false;
  const domain = process.env.REACT_APP_BASEURL + "/";
  const [fileList, setFileList] = useState([]);

  const fileUploadPropss = {
    action: process.env.REACT_APP_BASEURL + "/media/single-image-upload",
    name: "file",
    method: "POST",
    headers: {
      Authorization: `Bearer ${data}`,
      ContentType: "multipart/form-data",
    },
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5; // Checking file size is less than 5MB
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return false;
      }
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
        return false;
      }
      return true;
    },
  };

  useEffect(() => {
    if (editable) {
      if (imageUrl && imageUrl?.length > 0) {
        const checkArrary = Array.isArray(imageUrl) ? imageUrl : [imageUrl];
        const fileListData = checkArrary?.map((url, index) => ({
          uid: index,
          name: `image-${index + 1}`,
          status: "done",
          url: process.env.REACT_APP_BASEURL + "/" + url,
        }));
        setFileList(fileListData);
      }
    }
  }, [imageUrl]);

  const onChange = ({ fileList }) => {
    // Check if the file size is less than 5 MB (5 MB)
    const list = fileList?.filter((data) => {
      return !data?.size || data?.size / 1024 / 1024 < 5;
    });
    setFileList(list);
    // Check if the status is done and urls are present
    const urls = fileList?.map((data) => {
      if (data.status == "done") {
        return data?.response || data?.url?.substring(domain?.length);
      }
    });
    // Handle Image Upload For Edit Unlisted Paintings
    if (handleChange) handleChange(urls);
    if (setImg) setImg(urls);
  };

  const onRemove = (e)=>{
    form.setFieldsValue({ image: null });
    form.validateFields(["image"]);
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <div>
      <Upload
        {...fileUploadPropss}
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        showUploadList={true}
        multiple={multiImage ? true : false}
        name="image"
        accept=".jpg, .jpeg, .png"
        maxCount={multiImage ? 10 : 1}
        onRemove={e=>onRemove(e)}
      >
        {multiImage
          ? fileList.length < 10 && uploadButton
          : fileList.length < 1 && uploadButton}
      </Upload>
    </div>
  );
};


// Get Remember Email for LogIn
const remEmail = (form, setRemMe) => {
  const remMe = localStorage.getItem("rememberedEmail");
  if (remMe) {
    form.setFieldsValue({
      email: remMe,
    });
    setRemMe(true);
  }
};


// Clear Local Storage
const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("userData");
  localStorage.removeItem("logedIn");
};


// Antd Password and Confirm Password Validator
const validatePassword = (_, value, form) => {
  const { getFieldValue } = form;
  if (!value) {
    return Promise.reject("You cannot leave this field blank");
  } else if (value && value !== getFieldValue("new_password")) {
    return Promise.reject(
      "New password and confirm new password does not match"
    );
  }
  return Promise.resolve();
};


// Antd Rich-Text-Editor Content Validator
const validateEditorContent = (_, value) => {
  const maxLength = 5000;
  if (value && value.trim() !== "" && value !== "<p><br></p>") {
    if (value.length <= maxLength) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Can not be more than ${maxLength} characters.`));
    }
  }
  return Promise.reject(new Error("You cannot leave this field blank"));
};


// Order Status Color
const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return <div className="status-item-utils pending">Pending</div>;
    case 2:
      return <div className="status-item-utils in-progress">InProgress</div>;
    case 3:
      return <div className="status-item-utils shipped">Shipped</div>;
    case 4:
      return <div className="status-item-utils delivered">Delivered</div>;
    case 5:
      return <div className="status-item-utils cancelled">Cancelled</div>;
    default:
      return null;
  }
};

const StatusIndicator = ( status ) => {
  return getStatusColor(status || "");
};


export {
  ellipsis,
  idGenerator,
  SingleImageUploader,
  remEmail,
  userData,
  clearLocalStorage,
  validatePassword,
  StatusIndicator,
  BannerImageUpload,
  validateEditorContent,
  ImageUploader
};
