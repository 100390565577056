import {
  UilClipboardNotes,
  UilImage,
  UilCreateDashboard,
  UilFileShieldAlt,
  UilSetting,
  UilUsersAlt,
  UilListUiAlt,
  UilEllipsisV,
  UilTruck,
  UilPercentage,
  UilVectorSquare,
  UilImageEdit
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import propTypes from "prop-types";
import { NavTitle } from "./Style";
import versions from "../demoData/changelog.json";
import {
  changeDirectionMode,
  changeLayoutMode,
  changeMenuMode,
} from "../redux/themeLayout/actionCreator";

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = "/admin";

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : "";
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  // const changeLayout = (mode) => {
  //   dispatch(changeLayoutMode(mode));
  // };
  // const changeNavbar = (topMode) => {
  //   const html = document.querySelector("html");
  //   if (topMode) {
  //     html.classList.add("ninjadash-topmenu");
  //   } else {
  //     html.classList.remove("ninjadash-topmenu");
  //   }
  //   dispatch(changeMenuMode(topMode));
  // };
  // const changeLayoutDirection = (rtlMode) => {
  //   if (rtlMode) {
  //     const html = document.querySelector("html");
  //     html.setAttribute("dir", "rtl");
  //   } else {
  //     const html = document.querySelector("html");
  //     html.setAttribute("dir", "ltr");
  //   }
  //   dispatch(changeDirectionMode(rtlMode));
  // };

  // const darkmodeActivated = () => {
  //   document.body.classList.add("dark-mode");
  // };

  // const darkmodeDiactivated = () => {
  //   document.body.classList.remove("dark-mode");
  // };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t("dashboard")}
      </NavLink>,
      "dashboard",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilCreateDashboard />
        </NavLink>
      )
    ),

    getItem(t("Product Management"), "product-management", !topMenu && <UilClipboardNotes />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/product-management/listing`}>
          {t("Products Listing")}
        </NavLink>,
        "products-listing",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/product-management/category-linking`}>
          {t("Category Linking")}
        </NavLink>,
        "category-linking",
        null
      ),
    ]),

    getItem(t("Category Management"), "category-management", !topMenu && <UilListUiAlt />, [
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/products/allproducts`}>
      //     {t("Products")}
      //   </NavLink>,
      //   "allproducts",
      //   null
      // ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/categories`}>
          {t("Main Categories")}
        </NavLink>,
        "categories",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/sub-categories`}>
          {t("Sub Categories")}
        </NavLink>,
        "sub-categories",
        null
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/attributes`}>
      //     {t("Attributes")}
      //   </NavLink>,
      //   "attributes",
      //   null
      // ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/couponcode`}>
      //     {t("Coupon Code")}
      //   </NavLink>,
      //   "couponcode",
      //   null
      // ),
    ]),

    getItem(t("Supplier Management"), "supplier-management", !topMenu && <UilTruck />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/supplier-management/listing`}>
          {t("Supplier Listing")}
        </NavLink>,
        "supplier-listing",
        null
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/supplier-management/details`}>
      //     {t("Supplier Details")}
      //   </NavLink>,
      //   "supplier-details",
      //   null
      // ),
    ]),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/orders`}>
    //     {t("Orders")}
    //   </NavLink>,
    //   "orders",
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/notifications`}>
    //       <UilClipboardNotes />
    //     </NavLink>
    //   )
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/customers/allcustomers`}>
    //     {t("Customers")}
    //   </NavLink>,
    //   "customers",
    //   !topMenu && (
    //     <NavLink
    //       className="menuItem-iocn"
    //       to={`${path}/customers/allcustomers`}
    //     >
    //       <UilUsersAlt />
    //     </NavLink>
    //   )
    // ),
    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/markup-percentage`}>
    //     {t("Markup Percentage")}
    //   </NavLink>,
    //   "markup-percentage",
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/markup-percentage`}>
    //       <UilPercentage />
    //     </NavLink>
    //   )
    // ),

    // getItem(t("Custom Management"), "custom-management", !topMenu && <UilVectorSquare />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/custom-management/stitch-management`}>
    //       {t("Stitch Management")}
    //     </NavLink>,
    //     "stitch-management",
    //     null
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/custom-management/location-management`}>
    //       {t("Location Management")}
    //     </NavLink>,
    //     "location-management",
    //     null
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/custom-management/color-management`}>
    //       {t("Color Management")}
    //     </NavLink>,
    //     "color-management",
    //     null
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/custom-management/printsize-management`}>
    //       {t("Print Size Management")}
    //     </NavLink>,
    //     "printsize-management",
    //     null
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/custom-management/vinylprint-management`}>
    //       {t("Vinyl Print Management")}
    //     </NavLink>,
    //     "vinylprint-management",
    //     null
    //   ),
     
    // ]),


    // getItem(t("Clip Art Management"), "clipart", !topMenu && <UilImageEdit />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/clipart/main-categories`}>
    //       {t("Clip Art Categories")}
    //     </NavLink>,
    //     "main-categories",
    //     null
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/clipart/sub-categories`}>
    //       {t("Clip Art Sub-Categories")}
    //     </NavLink>,
    //     "sub-categories",
    //     null
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/clipart/images`}>
    //       {t("Clip Art Images")}
    //     </NavLink>,
    //     "images",
    //     null
    //   ),
    // ]),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/stitch-management`}>
    //     {t("Stitch Management")}
    //   </NavLink>,
    //   "stitch-management",
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/stitch-management`}>
    //       <UilVectorSquare />
    //     </NavLink>
    //   )
    // ),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/location-management`}>
    //     {t("Location Management")}
    //   </NavLink>,
    //   "location-management",
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/location-management`}>
    //       <UilVectorSquare />
    //     </NavLink>
    //   )
    // ),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/color-management`}>
    //     {t("Color Management")}
    //   </NavLink>,
    //   "color-management",
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/color-management`}>
    //       <UilVectorSquare />
    //     </NavLink>
    //   )
    // ),

    getItem(t("CMS"), "cms", !topMenu && <UilFileShieldAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/menu-management`} >
          {t("Menu Management")}
        </NavLink>,
        "menu-management",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/banner-management`}>
          {t("Banner Management")}
        </NavLink>,
        "banner-management",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/manage-home`}>
          {t("Manage Home")}
        </NavLink>,
        "manage-home",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/manage-about`}>
          {t("Manage About")}
        </NavLink>,
        "manage-about",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/artwork-requirement`}>
          {t("Artwork Requirement")}
        </NavLink>,
        "artwork-requirement",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/faqs`}>
          {t("FAQs Management")}
        </NavLink>,
        "faqs",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/product-page-info`} >
          {t("Product Page Info")}
        </NavLink>,
        "product-page-info",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/customization-guide`} >
          {t("Customization Guide")}
        </NavLink>,
        "customization-guide",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/privacy-policy`}>
          {t("Privacy Policy")}
        </NavLink>,
        "privacy-policy",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/return-policy`}>
          {t("Return Policy")}
        </NavLink>,
        "return-policy",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/terms`}>
          {t("Terms & Conditions")}
        </NavLink>,
        "terms",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/contact-us`}>
          {t("Contact Us")}
        </NavLink>,
        "contact-us",
        null
      ),
    ]),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/settings/site-settings`}>
        {t("Site Settings")}
      </NavLink>,
      "site-settings",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/settings/site-settings`}>
          <UilSetting />
        </NavLink>
      )
    ),


    // getItem(t("Settings"), "settings", !topMenu && <UilSetting />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/settings/site-settings`}>
    //       {t("Site Settings")}
    //     </NavLink>,
    //     "site-settings",
    //     null
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/settings/vat`}>
    //       {t("Vat Setting")}
    //     </NavLink>,
    //     "vat",
    //     null
    //   ),
    // ]),




  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
